<template>
  <div>
    <el-form label-width="125px">
      <el-form-item :label="item.exampleName" v-for="(item, index) in list" :key="index">
        <div class="image-container">
          <template v-for="(pItem, pIndex) in item.urlList">
            <el-image class="image" :src="pItem" :preview-src-list="item.urlList" :key="pIndex"></el-image>
          </template>
        </div>
      </el-form-item>
      <el-form-item label="活动执行描述">
        <el-input type="textarea" readonly v-model="actDesc"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          console.log('11111', val);
          (val.collectFormList || []).map((v) => {
            const item = v;
            if (v.attachmentList && v.attachmentList.length > 0) {
              item.urlList = v.attachmentList.map((m) => (m.url));
            }
            return item;
          });
          this.list = val.collectFormList || [];
          this.actDesc = val.actDesc || '';
        }
      },
    },
  },
  data() {
    return {
      list: [],
      actDesc: '',
    };
  },
};
</script>
<style lang="less" scoped>
  /deep/ .el-form-item__label{
    font-size: 12px;
  }
  .image-container{
    display: flex;
    flex-wrap: wrap;
    .image{
      width: 120px;
      height: 120px;
      margin: 0 20px 20px 0;
    }
  }
</style>
