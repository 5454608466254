<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import Result from './component/result.vue';

formCreate.component('Result', Result);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    getDetail(id) {
      request.get('/sfa/sfaTpmActController/loadTpmActCollectDetailTableById', { id }).then((res) => {
        this.setValue(res.result);
        this.setValue({
          result: res.result,
        });
      });
    },
  },
  created() {
    this.getFormRule('sfaCenter_activityActivityReport_tpmExecuteReport_form')
      .then((res) => {
        res.forEach((item) => {
          const v = item;
          v.props = {
            ...v.props,
            disabled: false,
            readonly: true, // 查看时只读
          };
        });
        this.getDetail(this.formConfig.id);
      });
  },
};
</script>
