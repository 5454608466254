var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "125px" } },
        [
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "el-form-item",
              { key: index, attrs: { label: item.exampleName } },
              [
                _c(
                  "div",
                  { staticClass: "image-container" },
                  [
                    _vm._l(item.urlList, function (pItem, pIndex) {
                      return [
                        _c("el-image", {
                          key: pIndex,
                          staticClass: "image",
                          attrs: {
                            src: pItem,
                            "preview-src-list": item.urlList,
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "活动执行描述" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", readonly: "" },
                model: {
                  value: _vm.actDesc,
                  callback: function ($$v) {
                    _vm.actDesc = $$v
                  },
                  expression: "actDesc",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }